import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Box,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
} from "@mui/material";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";

import { StyledContact } from "./Contact.styled";

import ImgIconDiagnosis from "../../images/icon-diagnosis.svg";
import ImgIconSplint from "../../images/icon-splint.svg";
import ImgIconTherapy from "../../images/icon-therapy.svg";

const ContactContent = ({ children }) => {
  return (
    <StyledContact>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Contact The Joyner Hand, PA.
            </Typography>
            <Box display="flex" justifyContent="center">
              <Box>
                <form
                  name="contact"
                  method="POST"
                  action="/thanks"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <Box display="flex" justifyContent="flex-start">
                    <Box p={2}>
                      <TextField
                        label="First Name"
                        type="text"
                        id="firstName"
                        name="firstName"
                        variant="filled"
                        required
                      />
                    </Box>
                    <Box p={2}>
                      <TextField
                        label="Last Name"
                        type="text"
                        id="lastName"
                        name="lastName"
                        variant="filled"
                        required
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start">
                    <Box p={2}>
                      <TextField
                        label="Email"
                        type="email"
                        id="email"
                        name="email"
                        variant="filled"
                        required
                      />
                    </Box>
                    <Box p={2}>
                      <TextField
                        label="Phone"
                        id="phone"
                        type="phone"
                        name="phone"
                        variant="filled"
                        required
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-start">
                    <Box p={2} sx={{ display: "block", width: "100%" }}>
                      <TextField
                        label="Message"
                        id="message"
                        name="message"
                        variant="filled"
                        required
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <Box p={2}>
                      <Button variant="contained">Submit contact info</Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="lg">
          <Box py={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} p={4}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <Box
                    component="img"
                    src={ImgIconDiagnosis}
                    alt="magnifying glass over hand"
                    sx={{ maxHeight: `80px` }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Experts in the diagnosis</strong>
                </Typography>
                <Typography paragraph align="center" color="common.white">
                  Hand surgeons are experts in the diagnosis and treatment of
                  problems of the hand, wrist, and forearm. Hand surgery is a
                  subspecialty of orthopedics, plastics, and general surgery
                  requiring an additional year or more of training after
                  completing five years of surgical residency. A qualified hand
                  surgeon is an expert in the complexities of hand anatomy and
                  microvascular surgery, which enables the surgeon to operate on
                  the small bones and delicate nerves, vessels, and other
                  structures in the hand, wrist, and forearm.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <Box
                    component="img"
                    src={ImgIconSplint}
                    alt="hand in splint"
                    sx={{ maxHeight: `80px` }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Non Surgical Treatment</strong>
                </Typography>
                <Typography color="common.white" align="center">
                  Although you may be referred to see a hand surgeon by your
                  doctor or the emergency department, that does not necessarily
                  mean you need surgery. Many conditions are primarily treated
                  nonsurgically with medications, injections, splints, or
                  therapy. Your hand surgeon will diagnose your problem and then
                  recommend the best course of treatment. Common problems
                  treated by a hand surgeon include carpal tunnel syndrome and
                  hand numbness, finger lacerations with tendon or nerve injury,
                  hand and wrist arthritis, and hand fractures.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <Box
                    component="img"
                    src={ImgIconTherapy}
                    alt="hand with cross"
                    sx={{ maxHeight: `80px` }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Seek Treatment</strong>
                </Typography>
                <Typography color="common.white" align="center">
                  If you have an injury or problem with your hand, wrist, or
                  forearm that is causing pain or is affecting your hand, you
                  may want to seek the treatment of a hand surgeon
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledContact>
  );
};

const Contact = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero-contact.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="common.white">
              <strong>Contact</strong>
            </Typography>
            <Typography variant="h4" align="center" color="common.white">
              Boca Raton and East Delray Beach Hand Surgeon and More …
            </Typography>
          </Box>
        </Box>
      </BgImage>
      <ContactContent />
    </>
  );
};

export default Contact;
