import * as React from "react";

import { Layout } from "../components/Layout";
import { Contact } from "../components/Contact";

export default function ContactPage() {
  return (
    <Layout>
      <Contact />
    </Layout>
  );
}
